:root {

    --simpli-color1_old: #924eff;
    --simpli-color1: #000467;
    --simpli-color2: #0038e3;
    --simpli-color3: #1b0943;
    --simpli-color4: #ff81f1;
    --simpli-color5: #000000;
    --simpli-color6_old: var(--simpli-primary);
    --simpli-color6: #000467;
    --simpli-color7: #3BA46F;
    --simpli-color8: #FA454C;
    --simpli-color-gris: #757575;
    --simpli-color-light-pink: #FFDBFB;
    --white: white;
    --pink: #ff2982;

    --blue:#3b86ff;
    --red:#FA454C;
    --orange:#ff933b;
    --green:#3BA46F;


    --simpli-primary: #F8C7EA;
    --simpli-primary2: #F8C7EA;
    --simpli-secondary: #000467;
    --simpli-secondary2: #00022C;
    --simpli-shadow-btn: #00000033;
    --simpli-titulos: #00022B;
    --simpli-tertiary: #545454;
    --simpli-forms: #FFF;
    --simpli-pink: #BA005C;
    --gris-azulado: #EFF0FF;
    --simpli-background: #F8F8F8;
    --simpli-cuarteto: #FCECFC;
}

//cambio de marca

[style*="color: #ba005c"] {
    color: #000467 !important;
}

[style*="color: #924eff"] {
    color: #000467 !important;
}

[style*="color: #673ab7"] {
    color: #000467 !important;
}

[style*="color: var(--simpli-primary)"] {
    color: #000467 !important;
}

[style*="color: var(--simpli-primary);"] {
    color: #000467 !important;
}

[background*="color: var(--simpli-primary);"] {
    background: #000467 !important;
}

[background*="color: #673ab7"] {
    background: #000467 !important;
}

input[type='checkbox'] {
    accent-color: var(--simpli-secondary) !important;
}

.primary-button {
    border-radius: 30px;
    border: 1px solid var(--simpli-primary2) !important;
    background: var(--simpli-primary) !important;
    color: var(--simpli-secondary) !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

    font-family: "DM Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin-right: 10px;
}

.secondary-button {
    border-radius: 30px;
    border: 1px solid var(--simpli-secondary) !important;
    background: var(--white) !important;
    color: var(--simpli-secondary) !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.20);

    font-family: "DM Sans" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    margin-right: 10px;
}

.label-button {
    color: var(--simpli-secondary) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--simpli-forms) !important;
}

.mat-mdc-input-element {
    color: var(--simpli-titulos);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mat-mdc-dialog-actions {
    justify-content: right !important;
}

.mdc-floating-label {
    color: var(--simpli-titulos) !important;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--invalid .mdc-floating-label {
    color: #ba1a1a !important;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    border-color: var(--simpli-secondary) !important;
    background-color: var(--simpli-secondary) !important;
}

.simpli-color-gris {
    color: var(--simpli-secondary);
}

.color-tertiary {
    color: var(--simpli-tertiary) !important;
}

.mat-mdc-menu-content {
    background: #FFFAFD !important;
}

.mat-datepicker-content {
    background: #FFFAFD !important;
}


.label-empty{
    color: var(--simpli-tertiary) !important;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.empty-msg {
    padding: 20px;
}



.mat-mdc-form-field-hint {
    color: var(--simpli-tertiary) !important;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.mat-mdc-form-field-error {
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

// .mat-sidenav-container {
//     .mat-drawer-backdrop {
//         background: transparent !important;
//     }
// }

//FIN CAMBIO DE MARCA


html {
    font-family: "DM Sans" !important;
}

body {
    margin: 0px !important;
    font-family: "DM Sans" !important;
    background: var(--simpli-background);
}


.mat-mdc-card-title{
    font-size: 17px !important;
}

.mat-mdc-card-subtitle {
    font-size: 15px !important;
}

.mat-mdc-dialog-container-with-actions .mat-mdc-dialog-content {
    padding: 20px 24px !important;
}

router-outlet {
    font-family: "DM Sans" !important;
}

.mdc-dialog__title::before {

    height: auto !important;
}

@media (max-width: 1024px){
    .mat-mdc-dialog-panel {
        max-width: fit-content !important;
    }

    .primary-button {
        margin-right: 0px;
        margin-bottom: 20px;
        width: 100%;
    }

    .secondary-button {
        margin-right: 0px;
        margin-bottom: 20px;
        width: 100%
    }

    .empty-msg {
        width: initial !important;
        max-width: 100% !important;
    }

    .mat-mdc-dialog-actions .mat-button-base+.mat-button-base, .mat-mdc-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base{

        margin-left: 0px !important;
    }

    .login {
            width: 95% !important;
            margin-top: -110px !important;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
    }
}

input {
    font-family: "DM Sans" !important;
}

.mat-mdc-dialog-panel {
    max-width: 100% !important;
}

.mat-mdc-dialog-content, .mat-mdc-dialog-title {
    font-family: "DM Sans" !important;
}

div {
    letter-spacing: 0 !important;
    font-size: 14px;
    font-family: "DM Sans" !important;
}


h1 {
    font-family: "DM Sans" !important;
    font-size: 22px !important;
}

.full-altura {
    max-height: 100% !important;
}

h2 {
    font-size: 18px !important;
}

h3 {
    font-size: 18px !important;
}


h4 {
    font-size: 18px !important;
}

th {
    font-family: "DM Sans" !important;
}

tr {
    font-family: "DM Sans" !important;
}

table {
    font-family: "DM Sans" !important;
}

small {
    font-family: "DM Sans" !important;
}


.mdc-button__label {
    font-family: "DM Sans" !important;
}

.mat-mdc-select-value {
    font-family: "DM Sans" !important;
}

.mdc-list-item__primary-text {
    font-family: "DM Sans" !important;
}

.mdc-list-item__primary-text {
    font-family: "DM Sans" !important;
}

.col-top
{
    margin-top: 10px;
    margin-bottom: 5px;
}

tr.mat-header-row {
    height: 35px !important;
}

.velo-inactivo
{
    position: absolute;
    top: 22;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffffbf;
    z-index: 9999;
}


.subtitulo-simpli{
    font-family: "DM Sans" !important;
    font-style: normal !important;;
    font-weight: 400 !important;;
    font-size: 16px !important;;
    line-height: 26px !important;;
    letter-spacing: 0.02em !important;;
    color: var(--simpli-tertiary) !important;
}

.titulo-simpli{
	font-family: "Sofia Sans Extra Condensed" !important;
    font-size: 29px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    color: var(--simpli-titulos) !important;
    line-height: 45px !important;
    margin: 0 !important;
    height: 40px !important;
    text-transform: lowercase !important;
}

.spinner-simpli {
    margin:0 auto;
    margin-top: 20px;
    margin-bottom: 5px;
    stroke: #ff81f1 !important;
}

.simpli-snack {

    .mdc-snackbar__surface {
        background: white !important;
    }

    .mdc-snackbar__label {
        color: var(--simpli-color3) !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'DM Sans'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: var(--simpli-color3);
    }

    .mdc-button__label {
        font-family: 'DM Sans'!important;
        color: var(--simpli-color3) !important;
    }
}

.simpli-snack-error {

    .mdc-snackbar__surface {
        background: #FA454C !important;
    }

    .mdc-snackbar__label {
        color: white !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'DM Sans'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: var(--simpli-color3);
    }

    .mdc-button__label {
        font-family: 'DM Sans'!important;
        color: var(--simpli-color3) !important;
    }
}

.simpli-snack-alert {

    .mdc-snackbar__surface {
        background: #FDEB8F !important;
    }

    .mdc-snackbar__label {
        color: #1B0943 !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'DM Sans'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: #1B0943;
    }

    .mdc-button__label {
        font-family: 'DM Sans'!important;
        color: #1B0943 !important;
    }
}

.simpli-snack-cesion {

    .mdc-snackbar__surface {
        background: #EEF2FF !important;
    }

    .mdc-snackbar__label {
        color: #1B0943 !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'DM Sans'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: #1B0943;
    }

    .mdc-button__label {
        font-family: 'DM Sans'!important;
        color: #1B0943 !important;
    }
}

.simpli-snack-success {

    .mdc-snackbar__surface {
        background: #3BA46F !important;
    }

    .mdc-snackbar__label {
        color: white !important;
    }

    .mat-mdc-snack-bar-label {
        font-family: 'DM Sans'!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 12px!important;
        line-height: 20px!important;
        letter-spacing: 0.02em!important;
        color: white;
    }

    .mdc-button__label {
        font-family: 'DM Sans'!important;
        color: white;
    }
}



.shadow-box {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15) !important;
}

.mat-option {
    font-family: "DM Sans" !important;
}

@media (max-width:700px) {


    .mat-dialog-actions {
        margin-top:20px !important;
        margin-bottom: 20px !important;
    }

}

.row_main
{
    width: 100%;
    overflow: hidden;
    padding: 1%;
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0;
    background: #F8F8F8;
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
    margin: 10px 0;
    padding: 10px;
    border-radius: 3px 3px 3px 3px;
    font-size: 14px;
}

.info-msg {
    color: #059;
    background-color: #BEF;
}

.success-msg {
    color: #270;
    background-color: #DFF2BF;
}

.warning-msg {
    color: #9F6000;
    background-color: #FEEFB3;
}

.error-msg {
    color: #D8000C;
    background-color: #FFBABA;
}

.mat-mdc-form-field
{
    width: 100%;
    font-family: "DM Sans" !important;
    margin-top: 10px;
    font-size: 13px !important;
}

.mdc-label {
    font-family: "DM Sans" !important;
}


.mdc-floating-label {
    font-family: "DM Sans" !important;
}

.mat-mdc-form-field-hint {
    font-family: "DM Sans" !important;
    font-size: 11px !important;
}


.mat-mdc-dialog-container::-webkit-scrollbar {
    height:6px;
    background-color: #F5F5F5;
    width: 5px;
}

.mat-mdc-dialog-container::-webkit-scrollbar-thumb {
    background-color: #7b7b7b;
    border-radius: 5px;
}


.mat-mdc-dialog-container::-webkit-scrollbar-track {
    background-color: transparent;
}


.mat-radio-button {

    font-family: "DM Sans" !important;
}

.mat-dialog.title {
    font: inherit !important;
}

.no-input {
    width: 100%;
    border: none;
    background: none;
    box-shadow: none;
    font-family: "DM Sans" !important;
}



.no-input:focus-visible {
    border: none !important;
    outline: none !important;
}

.sinRegistros {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px !important;
    color: #7e7e7e;
    font-family: "DM Sans";
    width: 100%;
    min-height: 150px;
    background: #f7f7f7;
    border-radius: 5px;
  }

.mat-mdc-menu-panel  {
    min-width: 112px !important;
    max-width: 400px !important;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
    max-height: calc(100vh - 48px) !important;
    border-radius: 4px !important;
    outline: 0 !important;

}

.add_factoring::-webkit-scrollbar {
    height:5px;
    background-color: #F5F5F5;
    width: 6px;
    }

.add_factoring::-webkit-scrollbar-thumb {
    background-color: #959496;
    border-radius: 5px;
}

.add_factoring::-webkit-scrollbar-track {
    background-color: transparent;
}

.mdc-tooltip__surface {
    font-family: "DM Sans" !important;
    font-size: 14px !important;
}

.mat-mdc-dialog-content::-webkit-scrollbar {
    height: 5px;
    background-color: #f5f5f5;
    width: 8px !important;
}

.mat-mdc-dialog-content::-webkit-scrollbar-thumb {
    background-color: #959496;
    border-radius: 5px;
}

.mat-mdc-dialog-content::-webkit-scrollbar-track {
    background-color: transparent;
}

.mat-simple-snackbar-action {
    color: #673ab7;
}

.logo_home
{
    width: 100%;
    transition: all .17s ease-in-out;
    text-align: right;
}

.logo_home_active
{
    margin-left: -103px;
}

@media only screen and (max-width: 768px) {
    .logo_home
    {
        height: 30px;
        position: absolute;
        left: 50%;
        margin-left: -118px;
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 598px) {
    .logo_home {
        height: 30px;
        position: absolute;
        left: 50%;
        margin-left: -122px;
        font-size: 18px !important;
    }
}

.toolbar-spacer {
    flex: 1 1 auto;
}

.mat-mdc-menu-panel.menu-user-simpli {
    width: 400px;
}

.mat-mdc-menu-panel.menu-user-simpli-detail {
    width: 300px;
}

.icon-suffix {
    color: var(--simpli-secondary) !important;
}

.menusimpli {
    border: none;
    width: 220px !important;
    overflow: hidden;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    .texto-menu{
        display: block !important;
    }

    .col4simpli {
        flex-basis: 33.33333333% !important;
        max-width: 33.33333333% !important;
    }
}

// .menusimpli:hover {
//     width: 265px !important;

//     .col4simpli {
//         flex-basis: 33.33333333% !important;
//         max-width: 33.33333333% !important;
//     }

//     .texto-menu{
//         display: block !important;
//     }
// }

.texto-menu {
    display: none;
    transition: all .5s ease-in-out;
}

.col4simpli {
    flex-basis: 100%;
    max-width: 100%;
    text-align: left;
}

:ng-deep .lale .mat-list-item
{
    height: 80px !important;
   /* border-right: 2px solid; */
    width: 105px !important;
}

:ng-deep .lale .mat-list-item .icon-container
{
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -12px;
}

:ng-deep .lale .mat-list-item .label
{
    position: absolute;
    bottom: -6px;
    width: 105px;
    text-align: center;
    left: 1px;
}

:ng-deep .lale .level-1
{
    margin-left: 3px !important;
}

:ng-deep .lale .level-1 .mat-list-item
{
    height: 50px !important;
}

:ng-deep .lale .exp_simulation .mat-expansion-panel-body
{
    visibility: visible !important;
    background: var(--color-letra) !important;
    width: 102% !important;
}

:ng-deep .lale .selected-amml-item
{
    border-right: 7px solid var(--color-letra);
}


:ng-deep .lale .mat-list-item .ng-trigger
{
    left: 78px;
    position: absolute;
    top: 23px;
    display:none
}

:ng-deep .lale .anml-data .icon-container
{
    background:var(--main-color);;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    padding-left: 11px;
    margin-left: -22px;
    padding:0;
}

:ng-deep .lale .anml-data .icon-container .amml-icon
{
    color:#5c5c5c;
    margin-left: 10px;
}


:ng-deep .lale .selected-amml-item .anml-data .icon-container
{
    background: linear-gradient(45deg, #9141f6, #433da9);

}

:ng-deep .lale .selected-amml-item .anml-data .icon-container .amml-icon
{
    color: var(--main-color);
}

:ng-deep .lale .level-1 .selected-amml-item  .anml-data .icon-container
{
    background:var(--main-color);

}

:ng-deep .lale .level-1 .selected-amml-item  .anml-data .icon-container .amml-icon
{
    color: #5c5c5c
}


:ng-deep .lale .menu .material-icons
{
    margin-right: 20px;
    color:#5c5c5c
}

:ng-deep .lale .text_menu
{
    font-size: 14px;
    margin-top: 3px;
    font-weight: 500;
}
:ng-deep .lale .mat-list-item
{
    font-size: 14px !important;
    font-weight: 300;
    margin-top: 2px;
    border: none;
    margin-left: 9px;
}

:ng-deep .lale .amml-icon
{
    color: #5c5c5c
}

:ng-deep .lale .mat-list-base .mat-list-item {
    color: #5c5c5c !important;
    font-family: "DM Sans";
}

:ng-deep .lale .mat-list-base {
    padding-top: 0px !important;
}

:ng-deep .lale .ng-star-inserted > mat-divider
{
    display: none;
}

:ng-deep .lale .mat-drawer-inner-container
{
    background:  var(--main-color);
    overflow: hidden !important;
}

:ng-deep .lale .mat-drawer-content {
    overflow: hidden !important;
}

:ng-deep .lale .selected-amml-item
{
    font-weight: 600;
}



:ng-deep .lale .mat-drawer
{
    background:var(--main-color);
}

:ng-deep .lale .selected-amml-item {
    border-right: 5px solid #773acc;
    width: 110px !important;
}

.mat-elevation-z3 {
    box-shadow: 0 3px 3px -2px rgba(255, 255, 255, 0.2), 0 3px 4px 0 rgba(247, 245, 245, 0.17), 0 1px 8px 0 rgba(0,0,0,.12) !important;
}

.empty-card {
    text-align: center;
    background: whitesmoke;
    margin-top: 50px;
    font-weight: 300;
}

.box_placeholder
{
    text-align: center;
    margin-top: 15%;
    font-size: 15px;
    color: #7e7e7e;
    font-family: "DM Sans";
    background: whitesmoke;
    padding: 40px;
    border-radius: 4px;
}

.box_placeholder .material-icons
{
    font-size: 40px;
    margin-bottom: 10px;
}

.ball {
    width: 100px;
    height: 70px;
    border-radius: 50%;
    animation: bounce .8s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(.36,.07,.19,.97);
    animation-iteration-count: 2;
  }

  @keyframes bounce {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }

      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%, 60% {
        transform: translate3d(4px, 0, 0);
      }
  }


#footer-options-bar {
    position: fixed;
    width: 50%;
    height: auto;
    background: #EEF2FF;
    margin: 0 auto;
    opacity: 0.95;
    bottom: 0;
    left: 10%;
    right: 10%;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 20px;
    bottom: 50px;
    z-index: 9;
}

.footer-options-btn {
    width: 90%;
    height: 50px;
    border-radius: 10px !important;
    background: transparent;
    border: 2px solid white !important;
    color: white;
    align-items: center;
}

.footer-options-text {
    color: white;
    margin-left: 1%;
    margin-top: 1%;
    margin-bottom: 1%;
}

.footer-options-text h3,
p {
    margin-top: 3px;
    margin-bottom: 1px;
}

.justify-content-center {
    justify-content: center;
    display: flex;
}

.align-items-center {
    align-items: center;
    display: flex;
}

@media (max-width:700px) {
    #footer-options-bar {
        width: 100%;
        left: 0%;
        right: 0%;
        text-align: center;
        bottom: 110px;
    }

    .footer-options-btn {
        margin-top: 3px;
        margin-bottom: 2%;
        justify-content: center;
        text-align: center;
    }

    .footer-options-text {
        margin-left: 0%;
        margin-top: 0%;
        margin-bottom: 2%;
    }

    .footer-options-text p {
        display: none;
    }

    .col-footer-options {
        justify-content: center;
    }
}

.card-simpli mat-mdc-card {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.mat-mdc-card {
    padding: 16px !important;
    background: white !important;
}

.mat-mdc-dialog-surface {
    background: white !important;
}

.cajaGrande{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45vh;
    border: 1px solid #f3f3f3;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 50px;
  }

  .textoCajaGrande{
    text-align: center;
    font-weight: 500;
    color:#858489;
    font-size: 1em;
    font-family: "DM Sans";
    width: 70%
  }

  .icon-simpli {
    color: var(--simpli-color3);
    margin-right: 15px;
    font-size: 16px;

}

tr.mat-mdc-row, tr.mat-footer-row {
    height: 35px !important;
}

th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
    min-width: 100px !important;
}

.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
.mat-mdc-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
}
.mat-row,
.mat-header-row {
    display: table-row;
}
.mat-cell,
.mat-header-cell {
    overflow: hidden;

}

th {
    text-align: center !important;
}

.no-input {
    text-align: center !important;
}

}

.table-responsive-simpli {

    .mdc-data-table__cell {
        box-sizing: content-box !important;
    }

    .mat-sort-header-container {
        justify-content: center !important;
    }

    tr.mat-mdc-row, tr.mat-footer-row {
        height: 35px;
    }

    tr.mat-mdc-footer-row {
        height: 35px;
    }

    .mat-mdc-header-row {
        text-align: center;
    }

    th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell .mdc-data-table__header-cell,  .mdc-data-table__cell {
        min-width: 120px;
    }

    th.mat-mdc-header-cell {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 135%;
        letter-spacing: 0.02em;
        color: #1B0943;
        text-align: center;
    }

    display: block;
    width: 100%;
    overflow-x: auto;
    .mat-mdc-table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 1rem;
        display: table;
        border-collapse: collapse;
        margin: 0px;
		background: none;
		margin-top: 20px;
    }
    .mat-row,
    .mat-header-row {
        display: table-row;
    }
    .mat-cell,
    .mat-header-cell {
        overflow: hidden;

    }

    .no-input {
        text-align: center;
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 135%;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
    }

    td {
        text-align: center !important;
    }
}

.simpli-floating-box-button {
    font-family: "DM Sans";
    border: none;
    outline: none;
    color: #fff;
    padding: 0.625rem 1.25rem;
    cursor: pointer;
    border-radius: 0.312rem;
    font-size: 1rem;
    background: linear-gradient(45deg, var(--simpli-color1), var(--simpli-color2));
    font-weight: 400;
    margin-top: 6%;
    margin-bottom: 1%;
    height: 50px;
    width: 70%;
    -webkit-box-shadow: 3px 7px 24px -8px rgba(18,5,176,0.2) !important;
    box-shadow: 3px 7px 24px -8px rgba(18,5,176,0.2) !important;
}

.simpli-floating-box-button:hover {
    background-color: #287ae6;
    box-shadow: 0 1px 1px 0 rgba(66,133,244,0.45), 0 1px 3px 1px rgba(66,133,244,0.3);
}

.cursor-pointer {
    cursor: pointer;
}

.not-responsive {
    display: block !important;
}

@media (max-width: 1024px) {

    .not-responsive {
        display: none !important;
    }
}

.joyride-step__container {
    background: #FFFFFF !important;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15) !important;
    border-radius: 6px !important;
}

.joyride-step__buttons-container {
    width: 100%;
    justify-content: right !important;
}


.strong-label {
    font-weight: bold;
}

.square-paginator {
    padding: 10px;
    background: var(--simpli-secondary);
    color: var(--white);
    border: solid 1px var(--simpli-secondary);;
    margin-left: 5px;
    width: 20px;
    height: 20px;
}

@media (max-width: 1024px) {


    .btn-simpli-container {
        display: grid !important;
    }

    .menusimpli {
        width: 265px !important;

        .col4simpli {
            flex-basis: 33.33333333% !important;
            max-width: 33.33333333% !important;
        }

        .texto-menu{
            display: block !important;
        }
    }

}

.dialog-rosa .mat-mdc-dialog-container {
        background: #FFDBFB !important;
}

.btn-simpli-container {
	text-align: center;
	margin-top: 60px;
	margin-bottom: 30px;
}







.close-dialog {
	position: absolute;
	right: 0;
	top: -30px;
	cursor: pointer;

	svg {
		font-size: 30px;
		color: #000000;
		cursor: pointer;
	}
}

//RESPONSIVE SIMPLI

.row-responsive {
    padding: 1%;
    margin: 0 auto;
    background: white !important;
    background-color: white !important;

    .card-tabla {
        background:none !important;
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        margin-top: 0px !important;
    }
}



@media (max-width: 1024px) {
    .responsive-simpli {
        display:none;
    }

    .titulo-simpli-responsive {
        font-family: "DM Sans";
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 15px!important;
        letter-spacing: 0.01em!important;
        color: #1B0943!important;
        text-align: center!important;
        margin: 0 auto!important;
    }

    .subtitulo-simpli-responsive {
        font-family: 'DM Sans'!important;
        font-style: normal!important;
        font-weight: 400!important;
        font-size: 14px!important;
        letter-spacing: 0.02em!important;
        color: #757575!important;
        text-align: center!important;
        margin: 0 auto!important;
        display: block!important;
        margin-top: 10px!important;
    }

    .mat-drawer-container {
        background-color: white !important;
        color: rgba(0,0,0,.87);
    }

    .margin-responsive {
        margin-top: 0px !important;
    }

    .mat-mdc-dialog-content {
        padding: 0px 10px !important;
    }

}

.invisible-scroll::-webkit-scrollbar {
    height:6px;
    background-color: #e8e3e388;
    width: 10px;
    }

.invisible-scroll::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 5px;
}

.invisible-scroll::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    background-color: #e8e3e388;
}

.dialogo-lateral .mat-mdc-dialog-container .mdc-dialog__surface{
    background: #f4edff !important;
    box-shadow: none !important;
}

.dialogo-full-screen-responsive  .mat-mdc-dialog-container .mdc-dialog__surface{
    width: 100%;
    height: 100%;
    background: #f4edff !important;
    box-shadow: none !important;
}

.dialogo-full-screen-responsive-white  .mat-mdc-dialog-container .mdc-dialog__surface{
    width: 100%;
    height: 100%;
    background: white !important;
    box-shadow: none !important;
}



.chat-ejecutivo-cliente .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0 !important;
    background: rgba(255, 255, 255, 0.7) url('assets/img/fondochat.png') no-repeat center center / cover !important;
    height: auto;

    .mat-mdc-dialog-content {
        margin: 0px 0px !important;
        padding: 0px 8px !important;
    }
}


.chat-ejecutivo-cliente-responsive .mat-mdc-dialog-container .mdc-dialog__surface{
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
    padding: 0 !important;
    background-image: url('assets/img/fondochat.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: auto;

    .mat-mdc-dialog-content {
        margin: 0px 0px !important;
        padding: 0px 8px !important;
    }
}

.tox-notifications-container {
    display: none !important;
}

.tox-statusbar__branding {
    display: none !important;
}

.dialog-pantalla-completa {
    width: 100vw;
    height: 100vh;
}

.mat-select-min-line {
    font-family: "DM Sans" !important;
}

.mdc-data-table__cell {
    text-overflow: clip !important;
}

.mat-mdc-dialog-actions {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.stroked-btn{
    width: 200px;
    margin-left: 10px !important;
}

.mat-sm .mat-mdc-form-field  {
    height: 30px !important;
    line-height: 30px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
 }
 .mat-sm .mat-mdc-text-field-wrapper {
    height: 30px !important;
    line-height: 30px !important;
 }

 .mat-sm .mat-mdc-form-field-flex {
    height: 30px !important;
    line-height: 30px !important;
 }

 .mat-sm .mat-mdc-form-field-infix {
    padding: 0 !important;
 }

 .mat-sm .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
 }

 .mat-step-text-label {
    font-family: "DM Sans" !important;
 }

 .add_factoring::-webkit-scrollbar {
    height:6px;
    background-color: transparent;
    width: 6px;
    }

.add_factoring::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 5px;
}

.add_factoring::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    background-color: transparent;
}

.mat-bottom-sheet-container {
    background: var(--simpli-background) !important;
}

.mat-bottom-sheet-container::-webkit-scrollbar {
    height:6px;
    background-color: transparent !important;
    width: 6px;
    }

.mat-bottom-sheet-container::-webkit-scrollbar-thumb {
    background-color: #d9d9d9 !important;
    border-radius: 5px;
}

.mat-bottom-sheet-container::-webkit-scrollbar-track {
    background-color: transparent !important;
}

.mdc-text-field {
    border-radius: 10px !important;
}

.mat-mdc-outlined-button[disabled], .mat-mdc-outlined-button.mat-mdc-button-disabled {
    color: #201a1b61 !important;
    border-color: #201a1b1f !important;
    background: #e0e0e0 !important;

}

.mat-calendar-body-selected { 
    background-color: var(--simpli-secondary) !important;
}

.mat-calendar-body-in-range::before {
    background: #5256c529 !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: var(--simpli-secondary) !important;
}

.mat-calendar-body-selected.mat-calendar-body-today {
    box-shadow: inset 0 0 0 1px var(--simpli-secondary, var(--simpli-secondary)) !important;
}

.mat-mdc-autocomplete-panel {
    background: #FFFAFD !important;
}

mat-paginator {
    background: none !important;
}

.mat-mdc-select-panel {
    background: white !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: #EFF0FF !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
    background-color: #EFF0FF !important;
}


.mat-drawer-backdrop.mat-drawer-shown  {
    background-color: transparent !important;
}